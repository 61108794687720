export default {
  en: {
    code: "en",
    name: "English",
    dir: "ltr"
  },
  uz: {
    code: "uz",
    name: "Uzbek",
    dir: "ltr"
  },
  ru: {
    code: "ru",
    name: "Русский",
    dir: "ltr"
  },
  oz: {
    code: "oz",
    name: "Узбек",
    dir: "ltr"
  }
}
